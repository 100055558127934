<!-- Aman -->
<img src="../../../../../../../assets/images/logos/aman.svg" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Cash through AMAN' || option === 'Cash through AMAN And OPay'||option === 'Cash'" />
<!-- Credit Card -->
<img src="../../../../../../../assets/images/logos/credit-card.svg" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Credit & Debit Card' || option === 'PaySky' || option === 'Card Payments'" />
<!-- Qassatly -->
<img src="../../../../../../../assets/images/logos/qassatly.png" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Qassatly'" />
<!-- Mobile Wallet -->
<img src="../../../../../../../assets/images/logos/mobile-wallet.png" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Mobile Wallet'" />
<!-- Fawry -->
<img src="../../../../../../../assets/images/logos/fawry.svg" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Cash Through Fawry' || option === 'Cash'" />
<!-- Meeza -->
<img src="../../../../../../../assets/images/logos/meeza.svg" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Meeza'" />
<!-- NBE -->
<img src="../../../../../../../assets/images/logos/nbe-logo.svg" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'NBE Installments' || option === '6 Months - NBE' || option === '12 Months - NBE' || option === '18 Months - NBE'" />
<img src="../../../../../../../assets/images/logos/All-banks.png" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Installments' ||
    option === '6 Months - Installments' ||
    option === '12 Months - Installments' ||
    option === '18 Months - Installments'
  " />
<img src="../../../../../../../assets/images/logos/offline.png" width="60" height="32" class="form-check-logo"
  *ngIf="option === 'Offline Payment'" />
<img src="../../../../../../../assets/images/logos/valu.png" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'ValU'" />
  <img src="../../../../../../../assets/images/logos/valu-souhoola.png" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Souhoola'" />
<img src="../../../../../../../assets/images/logos/bm-logo.jpg" width="80" height="32" class="form-check-logo" *ngIf="
    option === 'Banque Misr Installments' ||
    option === 'BM Installments' ||
    option === '6 Months - BM' ||
    option === '12 Months - BM' ||
    option === '18 Months - BM'
  " />
  <div  *ngIf="option === 'Aman Installments'">
    <img src="../../../../../../../assets/images/logos/aman.svg" width="60" height="32" class="form-check-logo" />
    <a href="https://bit.ly/46cnl6S" target="_blank" class="text-decoration-underline">Register your card with Aman</a>
  </div>
<img src="../../../../../../../assets/images/logos/contact.jpg" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Contact'" />
  <img src="../../../../assets/images/logos/halan.png" width="80" height="32" class="form-check-logo"
  *ngIf="option === 'Halan'" />
